import '@/styles/globals.scss';
import type { AppProps } from 'next/app';
import NextNProgress from 'nextjs-progressbar';
import MainProvider from '@/providers/MainProvider';

export default function App({ Component, pageProps }: AppProps) {
  return (
    <MainProvider Component={Component}>
      <NextNProgress color="#3f8ed3" startPosition={0.5} stopDelayMs={100} height={4} showOnShallow={true} />
      <Component {...pageProps} />
    </MainProvider>
  );
}
